import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const Answers = (props) => {
  return (
    <Container fluid="true">
      <ListGroup>
        {props.answers.map((answer) => (
          <ListGroup.Item key={answer.answer.toString()} variant={answer.correct ? 'success' : 'null'}>
              {answer.answer}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}

const QuestionListItem = (props) => {
  return (
      <ListGroup.Item key={props.id.toString()}>
        <Container fluid="true">
          <Row>
            <Col>
              <span>{props.question}</span>
            </Col>
            <Col>
              <Answers answers={props.answers} />
            </Col>
            <Col xs="1">
              <Button onClick={() => props.onDelete(props.id)} variant="danger">Delete</Button>
            </Col>
          </Row>
        </Container>
      </ListGroup.Item>
  )
}

const filterQuestions = (questions, filter=false) => {
  if (!filter || filter.length < 1) {
    return questions
  } else {
    return questions.filter((question) => {
      const text = [question.question, ...question.answers.map((answer) => answer.answer)].join(' ').toLowerCase()
      return text.includes(filter.toLowerCase())
    })
  }
}

const QuestionList = (props) => {
    if (!props.items) {
      return (
        <ListGroup key="loading">
            <ListGroup.Item variant="info">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </ListGroup.Item>
          </ListGroup>
      )
    } else  if (props.items.length < 1) {
      return (
        <ListGroup key="no-items">
            <ListGroup.Item variant="info">
              <span>No Questions</span>
            </ListGroup.Item>
          </ListGroup>
      )
    } else {
      const filteredQuestions = filterQuestions(props.items, props.filter || false)
      if (props.filter && filteredQuestions.length <  1) {
        return (
          <ListGroup key="no-matches">
            <ListGroup.Item variant="warning">
              <span>No Matches</span>
            </ListGroup.Item>
          </ListGroup>
        )
        } else {
        return (
          <ListGroup key="questions">
            {filteredQuestions.map((question) => 
              <QuestionListItem id={question.id} question={question.question} answers={question.answers} onDelete={props.onDelete} key={question.id}/>
            )}
          </ListGroup>
        )
      }
    }
}

export default QuestionList