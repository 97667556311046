import React from 'react';

import { API } from 'aws-amplify';
import awsconfig from '../aws-exports';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

const uuidv1 = require('uuid/v1');

class QuestionFormModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.id || 0,
            question: props.question || '',
            answers: props.answers || [{
                answer: '',
                correct: false,
                validAnswer: false
            }],
            validQuestion: false,
            tooManyCorrectAnswers: false,
            tooFewCorrectAnswers: true
        }
    }

    handleHideModal = () => {
        this.setState({
            id: 0,
            question: '',
            answers: [{
                answer: '',
                correct: false,
                validAnswer: false
            }],
            validQuestion: false,
            tooManyCorrectAnswers: false,
            tooFewCorrectAnswers: true
        })
        this.props.onHideModal();
    }

    handleAddAnswer = () => {
        this.setState({
            answers: this.state.answers.concat([{ answer: '', correct: false, validAnswer: false }])
        });
    }

    post = async (id, data) => {
        const apiName = awsconfig.aws_cloud_logic_custom[0].name
        await API.post(apiName, '/questions', {
            body: {
                'id': id,
                'data': JSON.stringify(data)
            }
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const valid = this.state.validQuestion && 
                        !this.state.tooManyCorrectAnswers && 
                        !this.state.tooFewCorrectAnswers &&
                        !this.state.answers.some(answer => !answer.validAnswer)

        if (!valid) {
            console.log('Form not valid')
            return;
        }

        var id = this.state.id

        if (!id || id <= 0) {
            const uuid = uuidv1()
            id = uuid.split('-')[0]
        }

        const answers = this.state.answers.map((answer) => {
            return { 'answer': answer.answer, 'correct': !!answer.correct }
        })

        console.log(answers)

        const data = {
            question: this.state.question,
            answers: answers
        }


        this.post(
            id,
            data
        )
            .then(() => this.handleHideModal())
            .then(() => this.props.onSubmit({ id, ...data }))
            .catch(err => console.log(err));
    }

    handleQuestionChange = event => {
        const question = event.target.value

        let validQuestion = true
        if (!question || question.length < 1) {
            validQuestion = false
        }

        this.setState({
            question: event.target.value,
            'validQuestion': validQuestion,
        })
    }

    handleAnswerAnswerChange = idx => event => {
        const newAnswers = this.state.answers.map((answer, sidx) => {
            if (idx !== sidx) return answer;
            const newAnswer = event.target.value
            const validAnswer = newAnswer && newAnswer.length > 0
            return { ...answer, 'answer': newAnswer , 'validAnswer': validAnswer};
        });

        this.setState({ answers: newAnswers });
    }

    handleAnswerCorrectChange = idx => event => {
        const newAnswers = this.state.answers.map((answer, sidx) => {
            if (idx !== sidx) return answer;
            return { ...answer, 'correct': !answer.correct };
        });

        const correctAnswerCount = newAnswers.filter(answer => answer.correct).length

        this.setState({ 
            answers: newAnswers, 
            tooFewCorrectAnswers: correctAnswerCount < 1, 
            tooManyCorrectAnswers: correctAnswerCount > 1,
        });
    }

    handleRemoveAnswer = idx => () => {
        if (this.state.answers.length > 1) {
            const newAnswers = this.state.answers.filter((_, sidx) => idx !== sidx)

            const correctAnswerCount = newAnswers.filter(answer => answer.correct).length

            this.setState({ 
                answers: newAnswers, 
                tooFewCorrectAnswers: correctAnswerCount < 1, 
                tooManyCorrectAnswers: correctAnswerCount > 1
            });
        } else {
            this.setState({
                answers: [{
                    answer: '',
                    correct: false,
                    validAnswer: false
                }],
                tooManyCorrectAnswers: false,
                tooFewCorrectAnswers: true
            })
        }
    }

    render() {
        const invalidCorrectCount = this.state.tooManyCorrectAnswers || this.state.tooFewCorrectAnswers
        const answers = this.state.answers.map((answer, idx, arr) => (
            <Form.Group className="answer" key={idx}>
                <Row>
                    <Col>
                        <Form.Control type="text" placeholder={`Answer ${idx + 1}`} value={answer.answer} onChange={this.handleAnswerAnswerChange(idx)} isInvalid={!answer.validAnswer} />
                    </Col>
                    <Col sm='2'>
                        <InputGroup>
                            <Form.Check
                                checked={answer.correct}
                                type='checkbox'
                                onChange={this.handleAnswerCorrectChange(idx)}
                            />
                            {invalidCorrectCount && <InputGroup.Append><span role="img" aria-label="warning">⚠</span></InputGroup.Append>}
                        </InputGroup>
                    </Col>
                    <Col sm='2'>
                        <button type="button" onClick={this.handleRemoveAnswer(idx)} disabled={arr.length < 2}>
                            X
                        </button>
                    </Col>
                </Row>
            </Form.Group>
        ))

        const valid = this.state.validQuestion && 
                        !this.state.tooManyCorrectAnswers && 
                        !this.state.tooFewCorrectAnswers &&
                        !this.state.answers.some(answer => !answer.validAnswer)

        return (
            <Modal show={this.props.show} onHide={this.handleHideModal}>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formQuestion" key="question">
                            <Form.Label>Question</Form.Label>
                            <Form.Control type="text" placeholder="Question" defaultValue={this.state.question} onChange={this.handleQuestionChange} required isInvalid={!this.state.validQuestion}/>
                        </Form.Group>

                        <Form.Group controlId="formAnswers" key="header">
                            <Row>
                                <Col>
                                    <Form.Label>Answers</Form.Label>
                                </Col>
                                <Col sm='2'>
                                    <span>Correct?</span>
                                </Col>
                                <Col sm='2'>
                                    <span>Remove</span>
                                </Col>
                            </Row>

                            {answers}

                            <Row>
                                <Col>
                                    <Button onClick={this.handleAddAnswer}>
                                        Add Answer
                                    </Button>
                                </Col>
                            </Row>

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleHideModal}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" onClick={this.handleSubmit} disabled={!valid}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export default QuestionFormModal