import React from 'react';

import { Auth } from 'aws-amplify';

import { withRouter } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Header extends React.Component {

    signOut = async (e) => {
        e.preventDefault();
        Auth.signOut()
            .then(() => {
                this.props.onStateChange('signedOut', null);
                console.log('signedOut')
            })
            .catch(err => console.log(err));
    }

    render() {
        return (
            <Navbar bg="light" expand="md">
                <Navbar.Brand href="/">Question Manager</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Form inline>
                        <Nav.Item onClick={this.signOut}>
                            <Nav.Link >
                                <Button variant="light">
                                    Logout {this.props.username || ''}
                                </Button>
                            </Nav.Link>
                        </Nav.Item>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withRouter(Header)
