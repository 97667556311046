import React from 'react';

import { API } from 'aws-amplify';
import awsconfig from '../aws-exports';

import QuestionList from '../components/QuestionList'
import QuestionFormModal from '../components/QuestionFormModal'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Questions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modalShown: props.showForm || false,
            questions: false,
            filter: '',
            page: 0
        }
    }

    componentDidMount() {
        this.loadQuestions();
    }
    
    async loadQuestions() {
        const apiName = awsconfig.aws_cloud_logic_custom[0].name
        const page = this.state.page
        const questionsResp = await API.get(apiName, '/questions/' + page);

        const newQuestions = questionsResp.map((questionResp) => {
            return {
                id: questionResp.id,
                ...JSON.parse(questionResp.data)
            }
        })

        const newState = Object.assign({}, this.state, {
            questions: newQuestions
        });

        this.setState(newState);
    }

    handleShowModal = () => {
        this.setState({modalShown: true})
    }

    handleHideModal = () => {
        this.setState({modalShown: false})
    }

    handleSubmitModal = (data) => {
        this.setState({
            questions: this.state.questions.concat([{ id: data.id, question: data.question, answers: data.answers }])
        });
    }

    handleFilterChange = event => {
        const val = event.target.value

        if (val.length >= 2) {
            this.setState({filter: val})
        } else {
            this.setState({filter: ''})
        }
    }
    
    async deleteQuestion(id) {
        const apiName = awsconfig.aws_cloud_logic_custom[0].name
        await API.del(apiName, '/questions/object/' + id)
    }

    handleDelete = (id) => {
        this.deleteQuestion(id)
        .then(() => {
            this.setState({
                questions: this.state.questions.filter((question) => question.id !== id)
            })
        }).catch(err => console.log(err));
    }

    render() {
        return (
            <Container fluid>
                <Row className="row-space">
                    <Col>
                        <Button onClick={this.handleShowModal}>
                            Add Question
                        </Button>
                    </Col>
                    <Col>
                        <Form>
                            <FormControl type="text" placeholder="Search" onChange={this.handleFilterChange}/>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <QuestionList items={this.state.questions} filter={this.state.filter} onDelete={this.handleDelete}/>
                    </Col>
                </Row>
                <QuestionFormModal show={this.state.modalShown} onHideModal={this.handleHideModal} onSubmit={this.handleSubmitModal} />
            </Container>
        )
    }
}

export default Questions;