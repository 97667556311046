import React from 'react';
import './App.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';


import { Switch, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css';

import Header from './components/Header'
import Questions from './pages/Questions'

Amplify.configure(awsconfig);

const App = (props) => (
  props.authState === 'signedIn' ?
    (<BrowserRouter>
      <div>
        <Header username={props.authData.username || false} />
        <Switch>
          <Route exact path="/" component={Questions} />
          <Route exact path="/home" component={Questions} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path="/questions/add" render={(props) => <Questions {...props} showForm={true} />} />
          <Route exact path="/questions/:page" component={Questions} />
          <Route exact path="/questions/edit/:id" component={Questions} />
        </Switch>
      </div>
    </BrowserRouter>)
    : null
)

export default withAuthenticator(App, false);